import React from "react";
import SEO from "../components/atoms/seo";

import Layout from "../components/organisms/layout";
import Heading from "../components/molecules/heading";
import BodyCopy from "../components/atoms/body-copy";

const PrivacyPolicyPage = () => (
  <Layout hideSecureForm>
    <SEO title="Privacy Policy" />
    <Heading title="Privacy Policy" weight="light" />
    <BodyCopy copy={"[--Privacy-Policy--]"} />
  </Layout>
);

export default PrivacyPolicyPage;
